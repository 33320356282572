import React from 'react'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import { formatDate } from 'src/format'
import Table from 'src/components/Table'
import FastingTable from 'src/components/Programme/FastingTable'
import { addDays } from 'date-fns'

const FastingSchedule = ({
  schedule,
  title = 'Fasting Schedule',
  startDate,
  durationDays,
  extraDurationText,
}) => {
  return (
    <>
      <Box mt={'1em'} maxWidth={800} mx={'auto'}>
        <Box ml={'1em'}>
          <Typography variant={'h3'} mt={'1em'}>
            {title}
          </Typography>
          {startDate && (
            <Typography variant={'body1'} my={'0.25em'}>
              From {formatDate(startDate)} until{' '}
              {formatDate(addDays(startDate, durationDays))}
              {extraDurationText && ' ' + extraDurationText}
            </Typography>
          )}

          <Table m={0}>
            <tbody>
              <tr>
                <td>Eat</td>
                <td>
                  <Box
                    borderRadius={3}
                    width={'1em'}
                    height={'1em'}
                    bgcolor={'green.0'}
                  />
                </td>
              </tr>
              <tr>
                <td>Fast</td>
                <td>
                  <Box
                    borderRadius={3}
                    width={'1em'}
                    height={'1em'}
                    bgcolor={'blue.light2'}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Box>
      </Box>
      <FastingTable schedule={schedule} />
    </>
  )
}

export default FastingSchedule

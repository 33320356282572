import React from 'react'
import Box from '@local/shared/components/System/Box'
import styled from 'styled-components'
import Table from 'src/components/Table'

const TableStyled = styled(Table)`
  border-spacing: 0;
  th {
    background-color: #fafafa;
  }

  tbody th,
  thead th {
    font-weight: normal;
  }

  tbody th,
  tbody td {
    vertical-align: top;
  }

  tbody th {
    padding: 0.5em;
  }

  thead th {
    vertical-align: bottom;
    text-align: center;
    padding: 0.5em;
    border-bottom: 1px solid #f1f1f1;
  }

  td,
  th {
    border: 1px solid #f1f1f1;
  }

  thead th {
    border-top: 0;
  }
  tbody th,
  thead th:first-child {
    border-left: 0;
  }

  tbody tr:last-child > * {
    border-bottom: 0;
  }

  & > * > tr > *:last-child {
    border-right: 0;
  }
`

const TdStyled = styled.td`
  background-color: ${({ eat, theme }) =>
    eat ? theme.palette.green['0'] : theme.palette.blue.light2};
`

const FastingProtocolTableRoot = styled(Box)`
  overflow: scroll;
  ${({ theme }) => theme.breakpoints.up('md')} {
    overflow: visible;
    margin: 0 auto;
    max-width: 800px;
  }
`

const COL_NAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const FastingTable = ({ schedule }) => {
  const cols = COL_NAMES.map((day, i) => ({ day, values: schedule[i] }))

  return (
    <FastingProtocolTableRoot>
      <Box
        mx={{ xs: '1em', md: 0 }}
        my={'1em'}
        display={'inline-block'}
        borderRadius={10}
        bgcolor={'blue.light2'}
        css={{ overflow: 'hidden' }}
        border={'solid 1px'}
        borderColor={'#fafafa'}
      >
        <TableStyled my={0}>
          <thead>
            <tr>
              <th />
              {cols.map(c => (
                <th key={c.day}>{c.day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>8am</th>
              {cols.map((c, i) => (
                <TdStyled key={i} eat={c.values[0]}>
                  <Box height={'3em'} />
                </TdStyled>
              ))}
            </tr>
            <tr>
              <th>1pm</th>
              {cols.map((c, i) => (
                <TdStyled key={i} eat={c.values[1]}>
                  <Box height={'4em'} />
                </TdStyled>
              ))}
            </tr>
            <tr>
              <th>7pm</th>
              {cols.map((c, i) => (
                <TdStyled key={i} eat={c.values[2]}>
                  <Box height={'5em'} />
                </TdStyled>
              ))}
            </tr>
            <tr>
              <th>10pm</th>
              {cols.map((c, i) => (
                <TdStyled key={i} eat={c.values[3]}>
                  <Box height={'4em'} />
                </TdStyled>
              ))}
            </tr>
          </tbody>
        </TableStyled>
      </Box>
    </FastingProtocolTableRoot>
  )
}

export default FastingTable
